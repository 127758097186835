import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

const PostLink = ({ post }) => {
  if (!post?.frontmatter?.image?.childImageSharp?.fluid) {
    return <div>Picture not found - {post.slug}</div>
  }
  return (
    <Link to={post.slug}>
      <div className="container mx-auto max-w-lg border rounded-2xl overflow-hidden shadow-lg transform hover:scale-110 transition duration-300">
        <Img fluid={post.frontmatter.image.childImageSharp.fluid}></Img>
      </div>
    </Link>
  )
}

export default PostLink
