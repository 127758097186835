import React from "react"
import PropTypes from "prop-types"
import Layout from "./Layout"

const IndexGridLayout = ({ children }) => {
  return (
    <>
      <Layout>
        <div className="grid gap-7 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 ">
          {children}
        </div>
      </Layout>
    </>
  )
}

IndexGridLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default IndexGridLayout
