import React from "react"
import { graphql } from "gatsby"
import PostLink from "../components/PostLink"
import IndexGridLayout from "../components/IndexGridLayout"
import SEO from "../components/Seo"

const Index = ({
  data: {
    allMdx: { edges },
  },
}) => {
  const Posts = edges
    // .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
    .map(edge => <PostLink key={edge.node.id} post={edge.node} />)

  return (
    <IndexGridLayout>
      <SEO
        title="Cynthia Noyd Studio"
        description="Cynthia Noyd is a teaching-artist."
      />
      {Posts}
    </IndexGridLayout>
  )
}

export default Index

export const pageQuery = graphql`
  query {
    allMdx(filter: { frontmatter: { title: { ne: "" } } }) {
      edges {
        node {
          id
          slug
          frontmatter {
            title
            image {
              childImageSharp {
                id
                fluid(maxWidth: 800, maxHeight: 800, cropFocus: CENTER) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`
